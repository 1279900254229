import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Nav, Navbar, Offcanvas} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useLayoutSidebarItems} from '../../hooks/layout';
import {useSession} from '../../contexts/SessionContext';
import logo from '../../assets/logo.svg';
import NavItem from '../../components/NavItem/NavItem';

import styles from './SectionSidebarMobile.module.scss';

const SectionSideBarMobile = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {useCallback} = React;
  const [sessionState] = useSession();

  const [SIDEBAR_TOP_ITEMS, SIDEBAR_BOTTOM_ITEMS] = useLayoutSidebarItems();

  const renderItem = useCallback(
    (item) =>
      item.showMobile && (
        <NavItem key={item.label} item={item} eventKey={item.path} onClick={handleClose} />
      ),
    [],
  );

  return (
    <Navbar expand={show} className={styles.background}>
      <Container fluid>
        <Navbar.Brand href="#">
          <Link to="/admin">
            <h1>
              <img src={logo} alt="2Blink logo" style={{maxHeight: 44}} />
            </h1>
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle onClick={handleShow} />

        <Navbar.Offcanvas placement="start" show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{sessionState.data.user.email}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className={styles.bodyContainer}>
              <Nav>
                <Nav>{SIDEBAR_TOP_ITEMS.map(renderItem)}</Nav>
              </Nav>
              <Nav className="mt-auto">{SIDEBAR_BOTTOM_ITEMS.map(renderItem)}</Nav>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default SectionSideBarMobile;
