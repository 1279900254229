import React, {useMemo} from 'react';
import {Nav, useAccordionButton, Accordion} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {IconName} from '@fortawesome/fontawesome-svg-core';
import styles from './NavItem.module.scss';

type NavLinkProps = {
  children: React.ReactNode;
  icon: IconName;
  eventKey: string;
  className?: string;
  onClick: any;
  href?: string;
  target?: string;
};

type Item = {
  id: string;
  icon: IconName;
  label: string;
  path: string;
  openInNewWindow: boolean;
  children: Item[];
  href?: string;
  target?: string;
  showMobile?: boolean;
};

type NavItemProps = {
  item: Item;
  eventKey: string;
  onClick: any;
};

const NavLink = ({children, icon, onClick, eventKey, className, href, target}: NavLinkProps) => (
  <Nav.Link className={className} eventKey={eventKey} onClick={onClick} href={href} target={target}>
    {icon ? <Icon icon={icon} /> : null} {children}
  </Nav.Link>
);

const NavItem = ({item, eventKey, onClick}: NavItemProps) => {
  const location = useLocation();
  const activeNavItem = useMemo(() => location.pathname, [location]);

  const navigate = useNavigate();

  const onClickInternal = useAccordionButton(
    eventKey,
    item.openInNewWindow ? () => window.open(item.path, '_blank') : () => navigate(item.path),
  );

  return (
    <Nav.Item onClick={onClick} className={styles.container}>
      <NavLink
        eventKey={eventKey}
        icon={item.icon}
        onClick={onClickInternal}
        href={item?.href}
        target={item?.target}
      >
        {item.label}
      </NavLink>
      {(item.children || []).length > 0 ? (
        <Accordion.Collapse eventKey={item.path}>
          <Nav activeKey={activeNavItem}>
            {item.children.map((child) => (
              <NavLink
                className={styles.subItem}
                eventKey={child.path}
                key={child.path}
                icon={child.icon}
                onClick={() => navigate(child.path)}
              >
                {child.label}
              </NavLink>
            ))}
          </Nav>
        </Accordion.Collapse>
      ) : null}
    </Nav.Item>
  );
};

export default NavItem;
