import {toast} from 'react-toastify';
import {initMercadoPago} from '@mercadopago/sdk-react';

export const initializeMercadoPago = (publicKey) => {
  initMercadoPago(publicKey);
};

export const handleMercadoPagoCheckout = async ({api, uid, values}) => {
  try {
    const response = await api.get(`/blink/${uid}/payment/mercadopago/create_preference`, values);
    return response.id;
  } catch (error) {
    toast.error('Error al crear la preferencia de MercadoPago');
    return null;
  }
};
