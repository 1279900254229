import React, {useEffect} from 'react';
import {toast} from 'react-toastify';
import {Formik} from 'formik';
import {Navigate} from 'react-router';
import {Button, Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useApi} from '../../contexts/ApiContext';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {useSession} from '../../contexts/SessionContext';
import UsernameField from '../../components/LoginFields/UsernameField';
import PasswordField from '../../components/LoginFields/PasswordField';
import {redirectToResetPassword} from '../../utils';

import styles from './StoreLogin.module.scss';
import SanitizedHTML from '../../components/SanitizedHTML/SanitizedHTML';

const StoreLogin = ({navigateTo}) => {
  const api = useApi();
  const [ecommerceState, ecommerceActions] = useEcommerce();
  const [, sessionActions, selectors] = useSession();
  const {t} = useTranslation('pages');

  useEffect(() => {
    // HubSpot is not loading correctly when the page is redirected.
    // To ensure it initializes properly, we reload the page once per session.
    // This should be replaced with a better solution in the future.
    const shouldReloadHubspot =
      ecommerceState.settings.COMPANY_HUBSPOT && selectors.isAnonymousCustomerSession;

    if (sessionStorage.getItem('hubspotReloadedOnLoginPage ') !== 'true' && shouldReloadHubspot) {
      sessionStorage.setItem('hubspotReloadedOnLoginPage ', true);
      window.location.reload();
    }
  }, [ecommerceState.settings.COMPANY_HUBSPOT, selectors.isAnonymousCustomerSession]);

  const handleSubmit = async (values, actions) => {
    try {
      await api.login(values);
      const data = await api.getSession();
      ecommerceActions.resetStore();
      sessionActions.setSession(data);
    } catch (e) {
      if (e.data?.detail) {
        toast.error(e.data?.detail, {hideProgressBar: true});
      }
      if (e.data?.username) {
        actions.setFieldError('username', e.data.username[0]);
      }
      if (e.data?.password) {
        actions.setFieldError('password', e.data.password[0]);
      }
    }
  };

  if (!selectors.isAnonymousCustomerSession) {
    return <Navigate to={navigateTo} />;
  }
  return (
    <Row className="g-0 mt-2 flex-grow-1 flex-shrink-1 h-100 align-items-start">
      <Col lg={6} md={12} className={`order-2 order-lg-1 ${styles.cards}`}>
        <Card border="primary">
          <Card.Body>
            <div className={`d-flex flex-column ${styles.loginMessage}`}>
              <SanitizedHTML dirtyHTML={ecommerceState.settings.COMPANY_LOGIN_MESSAGE} />
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg={6} md={12} className={`order-1 order-lg-2 ${styles.cards}`}>
        <Card bg="primary">
          <Card.Body>
            <Formik
              initialValues={{
                username: '',
                password: '',
              }}
              onSubmit={handleSubmit}
            >
              {(form) => (
                <Form noValidate onSubmit={form.handleSubmit}>
                  <UsernameField className="mb-2" />
                  <PasswordField />
                  <div className="text-center pb-1">
                    <Button
                      size="sm"
                      variant="link"
                      className="text-light text-decoration-none"
                      onClick={redirectToResetPassword}
                    >
                      {t('LoginPage.ForgotYourPassword')}
                    </Button>
                  </div>
                  <div className="text-center">
                    <Button
                      size="lg"
                      variant="primary-inverse"
                      type="submit"
                      disabled={form.isSubmitting}
                      className="d-flex mx-auto"
                    >
                      {form.isSubmitting ? (
                        <Spinner animation="border" />
                      ) : (
                        <div>{t('LoginPage.LogIn')}</div>
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default StoreLogin;
