import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {Col, Row, Nav, Dropdown, Container} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useSession} from '../../contexts/SessionContext';
import {useLayout} from '../../contexts/LayoutContext';
import {useLayoutSidebarItems} from '../../hooks/layout';

import styles from './SectionTopBar.module.scss';

const SectionTopBar = () => {
  const [sessionState] = useSession();
  const [layoutState] = useLayout();

  const [, SIDEBAR_BOTTOM_ITEMS] = useLayoutSidebarItems();

  const renderDropdownItem = useCallback((item, index) => {
    const icon = item.icon ? <Icon icon={item.icon} /> : null;

    return (
      <Dropdown.Item
        key={`sidebar-item-${index}`}
        onClick={item.onClick ? item.onClick : undefined}
        as={item.onClick ? undefined : Link}
        to={item.onClick ? undefined : item.path}
      >
        {icon} {item.label}
      </Dropdown.Item>
    );
  }, []);

  return (
    <Container fluid className={styles.container}>
      <Row>
        <Col xs={6}>
          {layoutState.title && (
            <h1>
              <Icon icon={layoutState.title.icon} /> {layoutState.title.label}
            </h1>
          )}
        </Col>
        <Col
          className={`d-flex align-items-center justify-content-end ${
            layoutState.topBar || layoutState.title ? styles.border : ''
          }`}
        >
          {layoutState.topBar && layoutState.topBar}
        </Col>
        <Col xs="auto">
          <Nav className="justify-content-end">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="px-0">
                <Icon icon="fa-regular fa-circle-user" className={styles.user} />
              </Dropdown.Toggle>
              <Dropdown.Menu className={styles.dropdownMenu}>
                <p>{`Hola, ${sessionState.data.user.email}`}</p>
                {SIDEBAR_BOTTOM_ITEMS.map(renderDropdownItem)}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default SectionTopBar;
