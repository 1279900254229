import React, {useMemo, useCallback} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Container, Row, Nav, Col, Accordion} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {pathBase} from '../../utils';
import {useLayoutSidebarItems} from '../../hooks/layout';
import logo from '../../assets/logo.svg';
import NavItem from '../../components/NavItem/NavItem';

import styles from './SectionSideBar.module.scss';

const SectionSideBar = () => {
  const location = useLocation();
  // Determine the active nav item by looking at the current path and taking the first two
  // segments.
  const activeNavItem = useMemo(() => pathBase(location.pathname), [location]);

  const [SIDEBAR_TOP_ITEMS, SIDEBAR_BOTTOM_ITEMS] = useLayoutSidebarItems();

  const renderItem = useCallback(
    (item) => <NavItem key={item.label} item={item} eventKey={item.path} />,
    [],
  );

  return (
    <Container fluid className={styles.container}>
      <Row className={styles.sideBarHeader}>
        <Col>
          <Link to="/admin">
            <h1>
              <img src={logo} alt="2Blink logo" />
            </h1>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion activeKey={activeNavItem}>
            <Nav activeKey={activeNavItem}>{SIDEBAR_TOP_ITEMS.map(renderItem)}</Nav>
          </Accordion>
        </Col>
      </Row>
      <Row className={styles.sideBarMain}>
        <Col>
          <Link to="/admin/blinks/add" className="btn btn-lg btn-secondary">
            <Icon className="align-middle" icon="fa-solid fa-circle-plus" />{' '}
            <span className="align-middle">Crear Blink</span>
          </Link>
        </Col>
      </Row>
      <Row className={styles.sideBarFooter}>
        <Col>
          <Nav activeKey={activeNavItem}>{SIDEBAR_BOTTOM_ITEMS.map(renderItem)}</Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default SectionSideBar;
