import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useApi} from '../../contexts/ApiContext';
import {useLayoutTitle} from '../../hooks/layout';
import DataTable from '../../components/DataTable';


const UsersPendingApprovalPage = () => {
  const api = useApi();
  const {t} = useTranslation('pages');

  useLayoutTitle({
    label: `${t('UsersPedingApprovalPage.title')}`,
    icon: 'fa-solid fa-user',
  });

  const formtDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const orderChoices = [
    {
      value: 'username',
      label: 'A → Z',
    },
    {
      value: '-username',
      label: 'Z → A',
    },
    {
      value: 'date_joined',
      label: `${t('UsersPage.DataTable.orderChoices.oldest')}`,
    },
    {
      value: '-date_joined',
      label: `${t('UsersPage.DataTable.orderChoices.newest')}`,
    },
  ];

  const columns = [
    {
      Header: `${t('UsersPage.DataTable.columns.headers.username')}`,
      accessor: 'username',
    },
    {
      Header: `${t('UsersPage.DataTable.columns.headers.email')}`,
      accessor: 'email',
    },
    {
      Header: `${t('UsersPage.DataTable.columns.headers.company')}`,
      accessor: 'user_company_name',
    },
    {
      Header: `${t('UsersPage.DataTable.columns.headers.creationDate')}`,
      Cell: ({cell}) => formtDate(cell.value),
      accessor: 'date_joined',
    }
  ];

  const buttons = useMemo(
    () => [
      {
        icon: 'fa-solid fa-circle-check',
        onClick: (cell) => {
          try {
            api.put(`/user/${cell.row.original.id}`,{
              username: cell.row.original.username,
              is_approved: true,
              type: cell.row.original.type,
            });
            toast.success( `${t('UsersPage.DataTable.columns.buttons.validate.message', {
              username: cell.row.original.username,
            })}`,
            {
              hideProgressBar: true,
            }
          )

          } catch (error) {
            toast.error(
              'Ups',
            {
              hideProgressBar: true,
            }
          );
          }
        },
        key: 'validate',
        refresh: true,
      },
      {
        icon: 'fa-solid fa-ban',
        onClick: (cell) => {
          api.put(`/user/${cell.row.original.id}`, {
            username: cell.row.original.username,
            is_active: false,
            type: cell.row.original.type,
          });

          toast.success(
            `${t('UsersPage.DataTable.columns.buttons.delete.message', {
              username: cell.row.original.username,
            })}`,
            {
              hideProgressBar: true,
            },
          );
        },
        key: 'delete',
        refresh: true,
      },
    ],
    [api, t],
  );

  return (
    <DataTable
      columns={columns}
      buttons={buttons}
      orderChoices={orderChoices}
      initialFilters={{approved: false}}
      source={{type: 'remote', url: '/user'}}
      allowOrder
      allowSearch
      searchPlaceholder="Busca usuarios..."
    />
  );
};


export default UsersPendingApprovalPage;